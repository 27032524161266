import _toConsumableArray from "D:/workspace/emind-ui-1.0/node_modules/@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import axios from 'axios';
import form from 'jquery-form';
import $ from 'jquery';
import { Modal } from 'ant-design-vue';
var columns_role = [{
  title: '角色',
  dataIndex: 'role',
  width: 200,
  scopedSlots: {
    customRender: '角色'
  }
}, {
  title: '角色名称',
  dataIndex: 'name',
  width: 200,
  scopedSlots: {
    customRender: '角色名称'
  }
}, {
  title: '备注',
  dataIndex: 'describe',
  width: 600,
  scopedSlots: {
    customRender: '备注'
  }
}];
var columns_equip_group = [{
  title: '设备组名称名称',
  dataIndex: 'name',
  width: 200,
  scopedSlots: {
    customRender: '设备组名称名称'
  }
}, {
  title: '备注',
  dataIndex: 'remark',
  width: 800,
  scopedSlots: {
    customRender: '备注'
  }
}];
export default {
  data: function data() {
    var _this = this;

    return {
      roleLoading: false,
      groupLoading: false,
      percent: 0,
      loading: false,
      refreshConfirmShow: false,
      equipGroupFormShow: false,
      equipGroupName: null,
      defaultEquipmentData: [],
      equipGroupReplaceFields: {
        key: 'id',
        children: 'children',
        title: 'name'
      },
      rolereplaceFields: {
        key: 'id',
        children: 'children',
        title: 'name'
      },
      menusData: [],
      equipmentData: [],
      equipGroups: [],
      tabId: 1,
      wrapperCol: {
        span: 18
      },
      labelCol: {
        span: 6
      },
      animated: false,
      roleSelectedRows: [],
      roleRowSelection: {
        columnWidth: 50,
        onChange: function onChange(selectedRowKeys, selectedRows) {
          _this.roleSelectedRows = selectedRows;
        }
      },
      equipGroupSelectedRows: [],
      equipGroupRowSelection: {
        columnWidth: 50,
        onChange: function onChange(selectedRowKeys, selectedRows) {
          _this.equipGroupSelectedRows = selectedRows;
        }
      },
      pagination: {
        defaultPageSize: 50,
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['10', '25', '50', '100'],
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          return _this.pageSize = pageSize;
        }
      },
      roles: [],
      total: 0,
      page: 1,
      length: 1000000000,
      showTotal: true,
      columns_role: columns_role,
      columns_equip_group: columns_equip_group,
      scroll: {
        y: 660
      },
      defaultCheckedMenusData: [],
      roleFormShow: false,
      roleForm: {
        id: null,
        name: null,
        describe: null,
        role: null,
        modules: ''
      },
      roleRules: {
        name: [{
          required: true,
          message: '请填写角色名称！',
          trigger: 'blur'
        }],
        role: [{
          required: true,
          message: '请填写角色(格式为：ROLE_SUPER_ADMIN)！',
          trigger: 'blur'
        }],
        describe: [{
          required: true,
          message: '请填写角色描述信息！',
          trigger: 'blur'
        }]
      },
      equipGroupForm: {
        id: null,
        name: null,
        remark: null,
        equipmentIds: []
      },
      equipGroupRules: {
        name: [{
          required: true,
          message: '请填写设备组名称！',
          trigger: 'blur'
        }],
        remark: [{
          required: true,
          message: '请填写设备组描述信息！',
          trigger: 'blur'
        }]
      },
      dialogTitle: '创建账户',
      keyboard: false,
      operationType: 0,
      okButtonProps: {
        props: {
          loading: false
        }
      },
      modalTiTle: '',
      uploadFormShow: false,
      downloadFormShow: false,
      okText: '提交',
      uploadType: 0,
      fileList: [],
      accept: '.json',
      download_timer: null
    };
  },
  mounted: function mounted() {
    var this_ = this;
    this_.getRoleList();
    this_.getmenusData();
  },
  destroyed: function destroyed() {
    clearInterval(this.download_timer);
  },
  methods: {
    cacheStatutoryHolidays: function cacheStatutoryHolidays() {
      axios({
        method: 'post',
        url: '/emind/real/data/cache/statutory/holidays'
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.$message.warning('服务器更新节假日缓存列表成功');
        } else {
          this_.$message.warning('服务器更新节假日缓存列表失败');
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    download: function download() {
      var this_ = this;

      if (this.uploadType == 2) {
        //配置文件
        this.download_timer = window.setInterval(function () {
          this_.percent += 3;

          if (this_.percent > 99) {
            clearInterval(this_.download_timer);
            this_.percent = 99;
          }
        }, 100);
        axios({
          method: 'get',
          url: '/emind/config/download',
          responseType: 'blob'
        }).then(function (res) {
          clearInterval(this_.download_timer);
          this_.percent = 100;
          this_.downloadFormShow = false;

          if ('download' in document.createElement('a')) {
            var url = window.URL.createObjectURL(res.data);
            var link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.setAttribute('download', res.headers["filename"]);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            navigator.msSaveBlob(res.data, res.headers["filename"]);
          }
        }).catch(function (err) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      } else if (this.uploadType == 5) {
        //App
        this.download_timer = window.setInterval(function () {
          this_.percent += 3;

          if (this_.percent > 99) {
            clearInterval(this_.download_timer);
            this_.percent = 99;
          }
        }, 100);
        axios({
          method: 'get',
          url: '/emind/config/app/download',
          timeout: 300000,
          responseType: 'blob'
        }).then(function (res) {
          clearInterval(this_.download_timer);
          this_.percent = 100;
          this_.downloadFormShow = false;

          if ('download' in document.createElement('a')) {
            var url = window.URL.createObjectURL(res.data);
            var link = document.createElement('a');
            link.style.display = 'none';
            link.href = url;
            link.setAttribute('download', res.headers["filename"]);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            navigator.msSaveBlob(res.data, res.headers["filename"]);
          }
        }).catch(function (err) {
          if (err.message != '') {
            this_.$message.error(err.message, 2, function () {});
            return;
          }
        });
      }
    },
    uploadSectionFile: function uploadSectionFile() {
      var this_ = this;

      if (this.uploadType == 3) {
        //配置文件
        if (this.fileList.length == 0) {
          this.$message.warning("请选择配置文件");
          return;
        }

        this_.okButtonProps.props.loading = true;
        var formData = new FormData();
        formData.append('file', this.fileList[0]);
        axios.post('/emind/config/upload', formData, {
          timeout: 300000
        }).then(function (d) {
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.code == 0) {
            this_.$message.success("配置文件上传成功");
          } else if (d.data.code == -1) {
            this_.$message.error("配置文件上传失败");
          }

          this_.uploadFormShow = false;
          this_.fileList = [];
          this_.okButtonProps.props.loading = false;
        }).catch(function (error) {
          this_.fileList = [];
          this_.uploadFormShow = false;
          this_.okButtonProps.props.loading = false;

          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      } else if (this.uploadType == 4) {
        //App
        if (this.fileList.length == 0) {
          this.$message.warning("请选择apk文件");
          return;
        }

        this_.okButtonProps.props.loading = true;

        var _formData = new FormData();

        _formData.append('file', this.fileList[0]);

        axios.post('/emind/config/app/upload', _formData, {
          timeout: 300000
        }).then(function (d) {
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.code == 0) {
            this_.$message.success("配置文件上传成功");
          } else if (d.data.code == -1) {
            this_.$message.error("配置文件上传失败");
          }

          this_.uploadFormShow = false;
          this_.okButtonProps.props.loading = false;
          this_.fileList = [];
        }).catch(function (error) {
          this_.fileList = [];
          this_.uploadFormShow = false;
          this_.okButtonProps.props.loading = false;

          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      }
    },
    uploadCancel: function uploadCancel() {
      this.uploadFormShow = false;
      this.downloadFormShow = false;
      this.fileList = [];
    },
    handleRemove: function handleRemove(file) {
      var index = this.fileList.indexOf(file);
      var newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload: function beforeUpload(file) {
      this.fileList = [].concat(_toConsumableArray(this.fileList), [file]);
      return false;
    },
    mouseout: function mouseout(type) {
      var id = '#my_button' + type;
      var dom = $(id);
      dom.css('box-shadow', 'none');
      dom.css('background', 'rgba(48, 48, 60, 0.6)');
    },
    mouseover: function mouseover(type) {
      var id = '#my_button' + type;
      var dom = $(id);
      dom.css('box-shadow', '0 2px 8px #fff');
      dom.css('background', 'rgba(48, 48, 60, 0.8)');
    },
    mouseup: function mouseup(type) {
      var id = '#my_button' + type;
      var dom = $(id);
      dom.css('box-shadow', '0 2px 8px #fff');
      dom.css('background', 'rgba(48, 48, 60, 0.8)');
    },
    mousedown: function mousedown(type) {
      var id = '#my_button' + type;
      var dom = $(id);
      dom.css('box-shadow', 'none');
      dom.css('background', 'rgba(48, 48, 60, 0.6)');
    },
    click: function click(type) {
      if (type == 1) {
        this.refreshConfirmShow = true;
      } else if (type == 2) {
        this.downloadFormShow = true;
        this.uploadType = 2;
        this.percent = 0;
        this.okText = '下载', this.modalTiTle = '下载配置文件';
      } else if (type == 3) {
        this.uploadFormShow = true;
        this.uploadType = 3;
        this.accept = '.json';
        this.okText = '上传', this.modalTiTle = '上传配置文件';
      } else if (type == 4) {
        this.uploadFormShow = true;
        this.uploadType = 4;
        this.accept = '.apk';
        this.okText = '上传', this.modalTiTle = '上传配App';
      } else if (type == 5) {
        this.downloadFormShow = true;
        this.uploadType = 5;
        this.percent = 0;
        this.okText = '下载', this.modalTiTle = '下载配置文件';
      }
    },
    closeRefreshConfirm: function closeRefreshConfirm() {
      this.refreshConfirmShow = false;
    },
    reflreshConfig: function reflreshConfig() {
      var this_ = this;
      this_.loading = true;
      axios({
        method: 'get',
        url: '/emind/config/cache/modification'
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        this_.loading = false;
        this_.refreshConfirmShow = false;

        if (d.data.code == 0) {
          this_.cacheStatutoryHolidays();
          this_.$message.success("配置刷新成功");
        } else if (d.data.code == -1) {
          this_.$message.warning("配置刷新失败");
        }
      }).catch(function (error) {
        this_.loading = false;
        this_.refreshConfirmShow = false;

        if (error.message != '') {
          this_.$Message.error(error.message, 2, function () {});
          return;
        }

        done();
      });
    },
    onMenuCheck: function onMenuCheck(node, event) {
      var this_ = this;
      this_.roleForm.modules = node.join(';').concat(';').concat(event.halfCheckedKeys.join(';'));

      if (this_.roleForm.modules.substr(this_.roleForm.modules.length - 1) == ';') {
        this_.roleForm.modules = this_.roleForm.modules.substr(0, this_.roleForm.modules.length - 1);
      }
    },
    onEquipCheck: function onEquipCheck(node, event) {
      var this_ = this;
      this_.equipGroupForm.equipmentIds = [];
      node.concat(event.halfCheckedKeys).map(function (id) {
        this_.equipGroupForm.equipmentIds.push(id);
      });
    },
    roleFormCancel: function roleFormCancel() {
      var this_ = this;
      this_.operationType = 0;
      this_.roleFormShow = false;
      this_.defaultCheckedMenusData = [];
      this_.roleForm = {
        id: null,
        name: null,
        describe: null,
        role: null,
        modules: ''
      };
    },
    equipGroupFormCancel: function equipGroupFormCancel() {
      var this_ = this;
      this_.operationType = 0;
      this_.equipGroupFormShow = false;
      this_.defaultCheckedMenusData = [];
      this_.roleForm = {
        id: null,
        name: null,
        remark: null,
        equipmentIds: []
      };
    },
    roleConfirm: function roleConfirm() {
      var this_ = this;
      this_.$refs['roleForm'].validate(function (valid) {
        if (valid) {
          this_.confirmDateForRole();
        }
      });
    },
    equipGroupConfirm: function equipGroupConfirm() {
      var this_ = this;
      this_.$refs['equipGroupForm'].validate(function (valid) {
        if (valid) {
          this_.confirmDateForEquipGroup();
        }
      });
    },
    confirmDateForEquipGroup: function confirmDateForEquipGroup() {
      var this_ = this;

      if (this_.operationType == 1) {
        axios({
          method: 'post',
          url: '/emind/config/equipment/group/creation',
          data: this_.equipGroupForm
        }).then(function (d) {
          //
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.code === '0') {
            this_.$message.success('设备组添加成功');
            this_.operationType = 0;
            this_.equipGroupFormShow = false;
            this_.defaultEquipmentData = [];
            this_.equipGroupForm = {
              id: null,
              name: null,
              remark: null,
              equipmentIds: []
            };
          } else {
            this_.$message.warning('设备组添加失败');
          }

          setTimeout(function () {
            this_.getEquipmentGroups();
          }, 200);
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      } else if (this_.operationType == 2) {
        axios({
          method: 'put',
          url: '/emind/config/equipment/group/modification',
          data: this_.equipGroupForm
        }).then(function (d) {
          //
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.code === '0') {
            this_.$message.success('设备组更新成功');
            this_.operationType = 0;
            this_.defaultEquipmentData = [];
            this_.equipGroupForm = {
              id: null,
              name: null,
              remark: null,
              equipmentIds: []
            };
            this_.equipGroupFormShow = false;
          } else {
            this_.$message.warning('设备组更新失败');
          }

          setTimeout(function () {
            this_.getEquipmentGroups();
          }, 200);
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      }
    },
    addEquipGroup: function addEquipGroup() {
      var this_ = this;
      this_.defaultEquipmentData = [];
      this_.getequipmentData();
      this_.operationType = 1;
      this_.dialogTitle = '新建设备组';
      this_.equipGroupForm = {
        id: null,
        name: null,
        remark: null,
        equipmentIds: []
      };
      this_.equipGroupFormShow = true;
    },
    editEquipGroup: function editEquipGroup() {
      var this_ = this;
      this_.dialogTitle = '更新设备组';

      if (this_.equipGroupSelectedRows.length != 1) {
        this.$message.warning('您还没有选择要更新的设备组，请先选择！');
        return;
      }

      this_.getequipmentData();
      this_.defaultEquipmentData = [];
      var selectEquipGroup = this_.equipGroupSelectedRows[0];
      this_.equipGroups.map(function (item) {
        if (item.id == selectEquipGroup.id) {
          selectEquipGroup = item;
        }
      });
      this_.equipGroupForm = {
        name: selectEquipGroup.name,
        remark: selectEquipGroup.remark,
        equipmentIds: [],
        id: selectEquipGroup.id
      };
      selectEquipGroup.relations.map(function (x) {
        this_.checked(x.equipmentId, this_.equipmentData);
      });
      this_.operationType = 2;
      this_.dialogTitle = '更新设备组';
      this_.equipGroupFormShow = true;
    },
    checked: function checked(id, data) {
      var this_ = this;
      data.forEach(function (item) {
        if (item.id == parseInt(id)) {
          if (item.children.length == 0) {
            this_.defaultEquipmentData.push(item.id);
          }
        } else {
          if (item.children.length != 0) {
            this_.checked(id, item.children);
          }
        }
      });
    },
    deleteEquipGroup: function deleteEquipGroup() {
      var this_ = this;
      var ids = '';

      if (this_.equipGroupSelectedRows.length < 1) {
        this.$message.warning('您还没有选择要删除的设备组，请先选择！');
        return;
      } else if (this_.equipGroupSelectedRows.length < 2) {
        ids = this_.equipGroupSelectedRows[0].id;
      } else {
        this_.equipGroupSelectedRows.map(function (x) {
          ids += x.id + ',';
        });
        ids = ids.substring(0, ids.length - 1);
      }

      axios({
        method: 'delete',
        url: '/emind/config/equipment/group/batch_deletion/' + ids
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.code === '0') {
          this_.$message.success('设备组删除成功');
        } else {
          this_.$message.error('请求出错', 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
      setTimeout(function () {
        this_.getEquipmentGroups();
      }, 200);
    },
    tabsChange: function tabsChange(activeKey) {
      var this_ = this;

      if (activeKey == 1) {
        this_.getRoleList();
        this_.getmenusData();
      } else if (activeKey == 2) {
        this_.getequipmentData();
        this_.getEquipmentGroups();
      } else if (activeKey == 3) {//				this_.cell_chart.dispose();
      } else {
        return;
      }

      this_.tabId = activeKey;
    },
    //获取刷新列表数据
    getRoleList: function getRoleList() {
      var this_ = this;
      this_.roleLoading = true;
      axios({
        method: "get",
        url: "/emind/user/roles",
        params: {
          page: this_.page,
          pageSize: this_.length
        }
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.status == 2000) {
          this_.total = d.data.data.totalCount;
          this_.roles = d.data.data.data;

          for (var i = 0; i < this_.roles.length; i++) {
            this_.roles[i].key = i;
          }
        }

        this_.roleLoading = false;
      }).catch(function (error) {
        this_.roleLoading = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    getEquipmentGroups: function getEquipmentGroups() {
      var this_ = this;
      var data = {
        name: this.equipGroupName,
        length: this_.length,
        startIndex: (this_.page - 1) * this_.length
      };
      this_.groupLoading = true;
      axios({
        method: 'post',
        url: '/emind/config/equipment/group/search',
        data: data
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        this_.total = d.data.recordsTotal;
        this_.equipGroups = d.data.data;

        for (var i = 0; i < this_.equipGroups.length; i++) {
          this_.equipGroups[i].key = i;
        }

        this_.groupLoading = false;
      }).catch(function (error) {
        this_.groupLoading = false;

        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    getequipmentData: function getequipmentData() {
      var this_ = this;
      axios({
        method: 'get',
        url: '/emind/config/equipment/list/2/-1'
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        this_.equipmentData = d.data.data;
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    },
    deleteRoles: function deleteRoles() {
      var this_ = this;
      var ids = [];

      if (this_.roleSelectedRows.length < 1) {
        this.$message.warning('您还没有选择要删除的角色，请先选择！');
        return;
      } else {
        this_.roleSelectedRows.map(function (x) {
          ids.push(x.id);
        });
      }

      this_.$confirm({
        title: "删除角色",
        content: '此操作将永久删除选中的角色, 是否继续?',
        okText: '确认',
        cancelText: '取消',
        onOk: function onOk() {
          axios({
            method: "delete",
            url: "/emind/user/roles",
            data: ids
          }).then(function (d) {
            //
            if (!d) {
              this_.$message.error('登录超时，请重新登录', 2, function () {});
              return;
            }

            if (d.data.status === "2000") {
              this_.$message.success('角色删除成功');
            } else {
              this_.$message.error('请求出错', 2, function () {});
            }

            setTimeout(function () {
              this_.getRoleList();
            }, 200);
          }).catch(function (error) {
            if (error.message != '') {
              this_.$message.error(error.message, 2, function () {});
              return;
            }
          });
        }
      });
    },
    editRole: function editRole() {
      var this_ = this;
      this_.operationType = 2;
      this_.dialogTitle = '更新角色';

      if (this_.roleSelectedRows.length != 1) {
        this.$message.warning('您还没有选择要更新的角色，请先选择！');
        return;
      }

      var selectRole = this_.roleSelectedRows[0];
      this_.roles.map(function (item) {
        if (item.id == selectRole.id) {
          selectRole = item;
        }
      });
      this_.getmenusData();
      this_.roleForm = {
        id: selectRole.id,
        name: selectRole.name,
        describe: selectRole.describe,
        role: selectRole.role,
        modules: selectRole.modules
      };
      this_.defaultCheckedMenusData = [];
      this_.roleForm.modules.split(';').forEach(function (item) {
        this_.defaultCheckedMenusData.push(parseInt(item));
      });
      var index = this_.defaultCheckedMenusData.indexOf(4);

      if (index != -1) {
        this_.defaultCheckedMenusData.splice(index, 1);
      }

      index = this_.defaultCheckedMenusData.indexOf(5);

      if (index != -1) {
        this_.defaultCheckedMenusData.splice(index, 1);
      }

      index = this_.defaultCheckedMenusData.indexOf(6);

      if (index != -1) {
        this_.defaultCheckedMenusData.splice(index, 1);
      }

      index = this_.defaultCheckedMenusData.indexOf(3);

      if (index != -1) {
        this_.defaultCheckedMenusData.splice(index, 1);
      }

      this_.dialogTitle = '更新设备';
      this_.roleFormShow = true;
    },
    addRole: function addRole() {
      var this_ = this;
      this_.getmenusData();
      this_.operationType = 1;
      this_.dialogTitle = '新建角色';
      this_.defaultCheckedMenusData = [];
      this_.roleForm = {
        id: null,
        name: null,
        describe: null,
        role: null,
        modules: ''
      };
      this_.roleFormShow = true;
    },
    confirmDateForRole: function confirmDateForRole() {
      var this_ = this;
      var user;

      if (this_.operationType == 1) {
        axios({
          method: 'post',
          url: '/emind/user/roles/role',
          data: this_.roleForm
        }).then(function (d) {
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.status === "2000") {
            this_.$message.success('角色添加成功');
            this_.operationType = 0;
            this_.roleFormShow = false;
            this_.roleForm = {
              id: null,
              name: null,
              remark: null,
              menuIds: []
            };
          } else {
            this_.$message.warning('角色添加失败');
          }

          setTimeout(function () {
            this_.getRoleList();
          }, 500);
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }
        });
      } else if (this_.operationType == 2) {
        axios({
          method: 'put',
          url: '/emind/user/roles',
          data: this_.roleForm
        }).then(function (d) {
          //
          if (!d) {
            this_.$message.error('登录超时，请重新登录', 2, function () {});
            return;
          }

          if (d.data.status === "2000") {
            this_.$message.success('角色更新成功');
            this_.operationType = 0;
            this_.roleFormShow = false;
          } else {
            this_.$message.warning('角色更新失败');
          }

          setTimeout(function () {
            this_.getRoleList();
          }, 500);
          this_.defaultCheckedMenusData = [];
          this_.roleForm = {
            id: null,
            name: null,
            remark: null,
            menuIds: []
          };
        }).catch(function (error) {
          if (error.message != '') {
            this_.$message.error(error.message, 2, function () {});
            return;
          }

          this_.defaultCheckedMenusData = [];
          this_.roleForm = {
            id: null,
            name: null,
            remark: null,
            menuIds: []
          };
        });
      }
    },
    sortId: function sortId(a, b) {
      return (a.sort - b.sort) * 1;
    },
    getmenusData: function getmenusData() {
      var this_ = this;
      axios({
        method: 'get',
        url: 'emind/user/menus/page'
      }).then(function (d) {
        //
        if (!d) {
          this_.$message.error('登录超时，请重新登录', 2, function () {});
          return;
        }

        if (d.data.status === "2000") {
          this_.menusData = d.data.data;
          this_.menusData.sort(this_.sortId);
        } else {
          this_.$message.error(d.data.message, 2, function () {});
        }
      }).catch(function (error) {
        if (error.message != '') {
          this_.$message.error(error.message, 2, function () {});
          return;
        }
      });
    }
  }
};